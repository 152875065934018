import React from "react"
import { FaCode, FaBoxOpen, FaChalkboardTeacher } from "react-icons/fa"

export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `I'm a Full stack web developer who can be useful in a variety of languages.  I can help your team by contributing quickly to your existing project.  Or If you're just getting started, I can also help build custom web sites and applications, from design to production ready product.`,
  },
  {
    id: 2,
    icon: <FaBoxOpen className="service-icon" />,
    title: "technology consulting",
    text: `Making choices about which technologies to use can be hard and have serious long term implications for your business.  I love helping guide people through that process no matter the scale of the project.  If you're looking for advice, please feel free to reach out with any questions, I'm happy to help!`,
  },
  {
    id: 3,
    icon: <FaChalkboardTeacher className="service-icon" />,
    title: "education",
    text: `I love teaching people about code and technology!!  I can help train you or your staff at any level, teaching you the nuances of speaking to a programmer in their natural habitat.  I can even get you started with learning code but I'm not sure why anyone would want to head down that path...`,
  },
]
